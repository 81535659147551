import { action } from "easy-peasy";

export default {
  setLoading: action((state, loading) => {
    state.loading = loading;
  }),
  setClient: action((state, client) => {
    state.client = client;
  }),
  setLogo: action((state, logo) => {
    state.logo = logo;
    const ele = document.getElementById("brandingLogo");
    ele.src = `https://kioskbranding.blob.core.windows.net/images/${logo}`;
  }),
  setFavicon: action((state, favicon) => {
    state.favicon = favicon;
    const ele = document.getElementById("brandingFavicon");
    ele.href = `https://kioskbranding.blob.core.windows.net/images/${favicon}`;
  }),
  setShowPopup: action((state, show) => {
    state.showPopup = show;
  }),
  setIPAddress: action((state, ip) => {
    state.ipAddress = ip;
  }),
  setDataUri: action((state, dataUri) => {
    state.cameraData.dataUri = dataUri;
  }),
  setAppBaseUrl: action((state, appBaseUrl) => {
    state.appBaseUrl = appBaseUrl;
  }),
  getDataUri: action((state) => {
    return state.cameraData.dataUri;
  }),
  getIPAddress: action((state) => {
    return state.ipAddress;
  }),
  getClient: action((state) => {
    return state.client;
  }),
  setImgClass: action((state, imgClass) => {
    state.cameraData.imgClass = imgClass;
  }),

  setAlertColor: action((state, color) => {
    state.alertColor = color;
  }),
  processChoochResSuccess: action((state, data) => {
    state.isRecognized = true;
    state.isNotRecognized = false;
  }),
  processChoochResFail: action((state, data) => {
    state.isRecognized = false;
    state.isNotRecognized = true;
    state.loading = false;
    state.showPopup = true;
    state.popupMessage = data.message;
    state.alertColor = data.alertColor;
    data.message
      ? (state.popupMessageHeader = "Attention!") //data.timeEntry.Message.value)
      : (state.popupMessageHeader = "Please see supervisor immediately");
  }),
  processCleanup: action((state, data) => {
    state.isRecognized = false;
    state.isNotRecognized = false;
    state.cameraData.dataUri = null;
    state.cameraData.imgClass = "hidden";
    state.loading = false;
    state.showPopup = false;
    state.popupMessage = null;
    state.popupMessageHeader = null;
    state.alertColor = "primary";
    state.txName = "";
    state.logObj = {};
  }),
  resetRecognition: action((state, payload) => {
    state.isNotRecognized = false;
    state.isRecognized = false;
  }),

  setTxName: action((state, txName) => {
    state.txName = txName;
  }),
  setLogObj: action((state, logObj) => {
    state.logObj = logObj;
  }),
  setLogObjBaccount: action((state, bAccount) => {
    state.logObj.BAccountID = bAccount;
  }),
  createLogStep: action((state, step) => {
    const tx = state.txName;

    const txStep = `${step ? tx + "-" + step : tx}`;
    const log = state.logObj;

    log[txStep]
      ? (log[txStep]["stop"] = new Date().toISOString()) &&
        (log[txStep]["length"] =
          (new Date(log[txStep]["stop"]) - new Date(log[txStep]["start"])) /
          1000)
      : (log[txStep] = { start: new Date(), stop: "" });

    state.logObj = log;
  }),
};
