import clockinStore from "./clockin";

export const initialState = {
  loading: true,
  alertColor: "primary",
  logo: "clientsFirst.png",
  favicon: "favicon.ico",
  client: "",
  ipAddress: "",
  appBaseUrl: "",
  cameraData: {
    dataUri: null,
    imgClass: "hidden",
  },
  showPopup: false,
  popupMessage: null,
  popupMessageHeader: null,
  txName: "",
  logObj: {},
  isRecognized: false,
  isNotRecognized: false,
};

export default {
  ...initialState,
  ...clockinStore,
};
