import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import store from "./store";
import { StoreProvider, createStore } from "easy-peasy";

import Navbar from "./components/layout/Navbar";
import Home from "./components/pages/Home";
import About from "./components/pages/About";

import "./DoosanBootstrap.css";
import "./style.css";
import "./App.css";

const initStore = createStore(store);

const App = () => {
  return (
    <StoreProvider store={initStore}>
      <Router>
        <Fragment>
          <Navbar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={About} />
          </Switch>
        </Fragment>
      </Router>
    </StoreProvider>
  );
};

export default App;
