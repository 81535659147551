import { /*action,*/ thunk } from "easy-peasy";
import setAuthToken from "../../utils/setAuthToken";
import { uploadToS3 } from "../../services/aws";
import {
  getSignedUrl,
  postChooch,
  loadCompanyMessage,
  postClockin,
} from "../../services/cfbsTimeClock";

import {
  createPostChoochPayload,
  createPostClockinPayload,
} from "./payloadBuilder";

export default {
  getCompanyCfg: thunk(async (actions) => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      let client = "";
      const tokenValue = queryParams.get("client");

      if (tokenValue) {
        localStorage.setItem("client", tokenValue);
        client = tokenValue;
      } else if (localStorage.getItem("client")) {
        client = localStorage.getItem("client");
      }
      await setAuthToken(client);

      actions.setLoading(true);
      const res = await loadCompanyMessage(client);
      const { ipAddress, appBaseUrl, logo, favicon, confirmedClient } =
        res.data;

      res.data.confirmedClient.length > 0 &&
        localStorage.setItem("client", confirmedClient);
      actions.setClient(confirmedClient);

      actions.setIPAddress(ipAddress);
      actions.setAppBaseUrl(appBaseUrl);
      actions.setLogo(logo);
      actions.setFavicon(favicon);
      actions.setLoading(false);
    } catch (error) {
      let msg = "";
      typeof error === "object" ? (msg = error.message) : (msg = error);
      //actions.setTimeProcessMsg(msg);
      actions.setAlertColor("danger");
      console.log("error", msg);
    }
  }),

  submitChooch: thunk(async (actions, payload, helpers) => {
    const ipAddress = helpers.getState().ipAddress;
    const { client, dataUri } = payload;
    const data = {
      client,
      dataUri,
      ipAddress,
    };
    try {
      const choochPayload = await createPostChoochPayload(data);
      const chooch = await postChooch(choochPayload);

      chooch.data.ipAddress = ipAddress;
      if (chooch.data.recog) {
        actions.processChoochResSuccess(chooch.data.timeEntry);
      } else {
        actions.processChoochResFail(chooch.data);
      }
      setTimeout(() => {
        actions.processCleanup(chooch.data);
      }, 3000);
    } catch (err) {
      console.log(err);
    }
  }),

  submitRecog: thunk(async (actions, payload, helpers) => {
    const ipAddress = helpers.getState().ipAddress;
    const { client, dataUri } = payload;
    const data = {
      client,
      dataUri,
      ipAddress,
    };

    let txName = `TX${parseInt(Math.random() * 10000).toString()}`;
    actions.setTxName(txName);
    actions.createLogStep("Frontend");

    try {
      actions.createLogStep("SignedUrl");
      // get signed url
      const signedUrl = await getSignedUrl();
      // upload to s3
      await uploadToS3(dataUri, signedUrl.signedRequest, signedUrl.url);

      actions.createLogStep("SignedUrl");

      data.fileUrl = signedUrl.url;
      actions.createLogStep("PostClockin");
      data.logObj = helpers.getState().logObj;
      data.txName = helpers.getState().txName;

      // send to backend cognitive/identity
      const clockinPayload = await createPostClockinPayload(data);
      await setAuthToken(data.client);

      const res = await postClockin(clockinPayload);

      actions.setLogObj(res.data.logObj);

      res.data.ipAddress = ipAddress;
      if (res.data.recog) {
        actions.processChoochResSuccess(res.data.timeEntry);
      } else {
        actions.processChoochResFail(res.data);
      }
      actions.createLogStep("PostClockin");
      actions.createLogStep("Frontend");

      // res.data &&
      //   res.data.timeEntry &&
      //   res.data.timeEntry.BAccountID &&
      //   res.data.timeEntry.BAccountID.value &&
      //   actions.setLogObjBaccount(res.data.timeEntry.BAccountID.value);

      setTimeout(
        () => {
          const logObj = helpers.getState().logObj;
          actions.processCleanup(res.data);
          console.log(JSON.stringify(logObj, null, 2));
        },
        res.data.recog ? 2000 : 5000
      );
    } catch (err) {
      // debugger;
      console.log(err);
      err.response &&
        err.response.data &&
        err.response.data.logObj &&
        actions.setLogObj(err.response.data.logObj);
      console.log(JSON.stringify(helpers.getState().logObj, null, 2));
      actions.processChoochResFail({
        timeEntry: {
          Message: { value: "There was a problem recording your time entry" },
          MessageHeader: { value: "Retry Clock-in" },
        },
        alertColor: "danger",
      });

      setTimeout(() => {
        actions.processCleanup(
          err && err.response && err.response.data ? err.response.data : {}
        );
      }, 5000);
    }
  }),
};
