import React from 'react';
import Clock from 'react-live-clock';

const KioskClock = () => {
  return (
    <div className="display-4">
      <Clock format={'LTS'} ticking={true} timezone={'US/Central'} />
    </div>
  );
};

export default KioskClock;
