import React, { useEffect } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import Popup from "../popup/Popup";
import CameraPhoto, { FACING_MODES } from "jslib-html5-camera-photo";

const videoRef = React.createRef();
let cameraPhoto = null;

const Home = (props) => {
  const {
    cameraData,
    showPopup,
    client,
    isRecognized,
    isNotRecognized,
    loading,
  } = useStoreState((state) => state);

  const { dataUri, imgClass } = cameraData;
  const { setLoading, setDataUri, setImgClass, submitRecog, getCompanyCfg } =
    useStoreActions((actions) => actions);

  useEffect(() => {
    cameraPhoto = new CameraPhoto(videoRef.current);
    let facingMode = FACING_MODES.USER;
    startCamera(facingMode, { width: 640, height: 480 });
    getCompanyCfg();

    // eslint-disable-next-line
  }, []);

  const startCamera = (idealFacingMode, idealResolution) => {
    cameraPhoto
      .startCamera(idealFacingMode, { width: 320, height: 240 }) //idealResolution)
      .then(() => {
        console.log("Camera is started!");
      })
      .catch((error) => {
        console.error("Camera not started!", error);
      });
  };

  const takePhoto = () => {
    setLoading(true);
    const config = {
      sizeFactor: 1,
      imageCompression: 0.95,
    };
    let cameraDataUri = cameraPhoto.getDataUri(config);

    setDataUri(cameraDataUri);
    setImgClass("taken");
    setTimeout(() => {
      setImgClass("hidden");
    }, 2500);
    setTimeout(() => {
      let payload = {};
      payload.client = client;
      payload.dataUri = cameraDataUri;
      submitRecog(payload);
    }, 500);
  };

  return (
    <div
      className={`${isRecognized && "alert-success"} ${
        isNotRecognized && "alert-danger"
      } h-100 d-flex`}
    >
      {loading && (
        <div className="d-flex justify-content-center w-100 align-items-center">
          <div className="spinner-border" />
        </div>
      )}

      <div
        className={`container jumbotron text-center ${loading && "d-none"}`}
        id="jumbo"
      >
        {showPopup ? <Popup /> : ""}
        <div id="camera" className="container mx-auto">
          <video id="camera--view" ref={videoRef} autoPlay playsInline></video>
          <img
            alt="imgCamera"
            src={dataUri}
            id="camera--output"
            style={{ width: "150px" }}
            className={imgClass}
          />
          <button
            id="camera--trigger"
            onClick={() => {
              takePhoto();
            }}
          >
            Clock-in / Clock-out
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
