import moment from "moment";

export const createPostChoochPayload = async (payload) => {
  return new Promise((resolve, reject) => {
    const body = {};
    if (payload.client) {
      body.client = payload.client;
    } else {
      return reject("Missing client in chooch call");
    }
    if (payload.dataUri) {
      body.dataUri = payload.dataUri;
    } else {
      return reject("Missing photo data chooch call");
    }
    if (payload.ipAddress) {
      body.ipAddress = payload.ipAddress;
    } else {
      return reject("Missing ip address in chooch call");
    }
    return resolve(body);
  });
};

export const createPostClockinPayload = async (payload) => {
  return new Promise((resolve, reject) => {
    const body = {
      time: moment().format(),
    };
    if (payload.client) {
      body.client = payload.client;
    } else {
      return reject("Missing client in clockin call");
    }
    if (payload.dataUri) {
      body.dataUri = payload.dataUri;
    } else {
      return reject("Missing photo data clockin call");
    }
    if (payload.ipAddress) {
      body.ipAddress = payload.ipAddress;
    } else {
      return reject("Missing ip address in clockin call");
    }
    if (payload.fileUrl) {
      body.fileUrl = payload.fileUrl;
    } else {
      return reject("Missing the file URL in clockin call");
    }
    if (payload.logObj) {
      body.logObj = payload.logObj;
    } else {
      return reject("Missing the log object in clockin call");
    }
    if (payload.txName) {
      body.txName = payload.txName;
    } else {
      return reject("Missing the tx name in clockin call");
    }
    return resolve(body);
  });
};
