import axios from "axios";

//routeBaseUrl will need changed in production.

 const routeBaseUrl = "https://cfbstimeclockprod.azurewebsites.net";
//const routeBaseUrl = "https://cfbstimeclockdev.azurewebsites.net";

// const routeBaseUrl = "http://localhost:8080";

// AWS SIGNED URL
export const getSignedUrl = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const s3Result = await axios({
        method: "GET",
        url: `${routeBaseUrl}/s3/sign-url`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (
        s3Result &&
        s3Result.data &&
        s3Result.data.url &&
        s3Result.data.signedRequest
      ) {
        return resolve({
          signedRequest: s3Result.data.signedRequest,
          url: s3Result.data.url,
        });
      }
      return reject("No response from s3");
    } catch (error) {
      return reject(error);
    }
  });
};

// CLOCKIN CALLS
export const loadCompanyMessage = async (client) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!client) {
        return reject("No Client Configuration Defined");
      }
      const res = await axios(`${routeBaseUrl}/clockin/${client}`);
      if (res && res.data) {
        return resolve(res);
      }
      return reject("Messages not retrieved");
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        error.message = error.response.data.error;
      }
      return reject(error);
    }
  });
};

export const postClockin = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios({
        method: "post",
        url: `${routeBaseUrl}/cognitive/identify`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data,
      });
      if (res && res.data) {
        return resolve(res);
      }
      return reject("Unable to clockin");
    } catch (error) {
      return reject(error);
    }
  });
};

// CHOOCH CALLS
export const postChooch = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios({
        method: "post",
        url: `${routeBaseUrl}/chooch/predict`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data,
      });
      if (res && res.status === 200) {
        return resolve(res);
      }
      return reject("Predict call failed");
    } catch (error) {
      return reject(error);
    }
  });
};
