import React from "react";
import PropTypes from "prop-types";
//import { Link } from 'react-router-dom';
import KioskClock from "../partials/Clock";
import { useStoreState } from "easy-peasy";

const Navbar = ({ title, icon }) => {
  let { logo } = useStoreState((state) => state.logo);
  !logo && (logo = "clientsFirst.png");

  return (
    <div className="navbar navbar-expand-sm navbar-light mb-1 position-fixed bg-white">
      <span className="navbar-brand">
        <img
          id="brandingLogo"
          src={`https://kioskbranding.blob.core.windows.net/images/${logo}`}
          style={{ width: "150px" }}
          alt=""
        />
      </span>
      <KioskClock />
      {/* <ul>
        <li>
          <Link to="/" className="text-primary">
            Home
          </Link>
        </li>
        <li>
          <Link to="/about" className="text-primary">
            About
          </Link>
        </li>
      </ul> */}
    </div>
  );
};

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

Navbar.defaultProps = {
  title: "Doosan Time Clock",
};

export default Navbar;
