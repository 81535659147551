import axiosS3 from 'axios';

export const uploadToS3 = async (file, signedRequest, url) => {
  return new Promise(async (resolve, reject) => {
    try {
      var options = {
        headers: {
          'Content-Type': 'image/png',
          'Content-Encoding': 'base64',
        },
      };
      var buf = new Buffer.from(
        file.replace(/^data:image\/\w+;base64,/, ''),
        'base64'
      );
      delete axiosS3.defaults.headers.common['Authorization'];
      const response = await axiosS3.put(signedRequest, buf, options);
      return resolve(response);
    } catch (error) {
      console.log('error', error);
      return reject(error);
    }
  });
};
