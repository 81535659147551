import React from "react";
import {
  /*Button,*/ Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useStoreState /*, useStoreActions*/ } from "easy-peasy";

const Popup = () => {
  const { showPopup, popupMessage, popupMessageHeader, alertColor } =
    useStoreState((state) => ({
      showPopup: state.showPopup,
      popupMessage: state.popupMessage,
      popupMessageHeader: state.popupMessageHeader,
      alertColor: state.alertColor,
    }));

  // const { setShowPopup, processCleanup } = useStoreActions((actions) => ({
  //   setShowPopup: actions.setShowPopup,
  //   processCleanup: actions.processCleanup,
  // }));

  // const toggle = () => {
  //   setShowPopup(false);
  //   processCleanup({});
  // };

  return (
    <div>
      <Modal isOpen={showPopup} centered={true}>
        <ModalHeader className={`${alertColor}-backgroud`}>
          {popupMessageHeader}
        </ModalHeader>
        <ModalBody className="text-center">
          {popupMessage
            ? popupMessage
            : "We were not able to succesfully recognize your face."}
        </ModalBody>
        <ModalFooter className={`${alertColor}-backgroud`}>
          {/* <Button
            color="danger"
            style={{ display: "block", width: "100%" }}
            onClick={toggle}
          >
            Ok
          </Button> */}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Popup;
